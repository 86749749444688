// --------------------
// styles
// --------------------
import '/css/app.css';

// --------------------
// Vendors
// --------------------

// jQuery
import $ from 'jquery';
window.jQuery = $; // 部分 vendor 依賴
window.$ = $;

// aos
import AOS from 'aos';
import 'aos/dist/aos.css';
setTimeout(() => {
  AOS.init();
}, 180);
